export const menuItems = [

    {
        id: 0,
        label: 'Dashboard',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/'
    },
    {
        id: 1,
        label: 'Users',
        icon: 'ri-user-2-fill',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/users'
    },
    {
        id: 2,
        label: 'Companies',
        icon: 'ri-building-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/companies'
    },

]