export const menuItems = [

    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/'
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/'
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/'
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/'
    },

]